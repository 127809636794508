import { HTTP } from "../../services/axios";
export default {
  state: {
    loading: false,
    currentUserLoggedIn: null,
    token: null,
    loginPrompt: false,
  },
  getters: {
    getLoginPrompt: (state) => state.loginPrompt,
    getLoggedInUser(state) {
      return state.currentUserLoggedIn;
    },
    token: (state) => state.token,
  },
  actions: {
    updateLoginPrompt({ commit }, value) {
      commit("setLoginPrompt", value);
    },
    async initializeSession({ commit }) {
      return new Promise((resolve) => {
        if (!localStorage.getItem("MIBITES-USER-TKN")) {
          return resolve();
        } else {
          const token = localStorage.getItem("MIBITES-USER-TKN");
          commit("UPDATE_LOGIN", token);
          HTTP.get("currentauthorinfo")
            .then((resp) => {
              commit("UPDATE_LOGIN_INFO", resp.data);
              return resolve();
            })
            .catch(() => {
              return resolve();
            });
        }
      });
    },
    AUTH_CHECK: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("authors/login", payload)
          .then((resp) => {
            let token = resp.data.token;
            commit("UPDATE_LOGIN", token);
            commit("UPDATE_LOGIN_INFO", resp.data.author);
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    AUTH_CHECK_GOOGLE: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("authors/login/google", payload)
          .then((resp) => {
            let token = resp.data.token;
            commit("UPDATE_LOGIN", token);
            commit("UPDATE_LOGIN_INFO", resp.data.author);
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    AUTH_SIGN_UP: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("authors/signup", payload)
          .then((resp) => {
            let token = resp.data.token;
            commit("UPDATE_LOGIN", token);
            commit("UPDATE_LOGIN_INFO", resp.data.author);
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    AUTH_LOGOUT: ({ commit }) => {
      return new Promise((resolve) => {
        commit("END_SESSION");
        resolve();
      });
    },
  },
  mutations: {
    setLoginPrompt(state, value) {
      state.loginPrompt = value;
    },
    UPDATE_LOGIN: (state, data) => {
      if (data) {
        localStorage.setItem("MIBITES-USER-TKN", data);
        HTTP.defaults.headers.Authorization = data;
        state.token = data;
      } else {
        console.log("no data");
      }
    },
    UPDATE_LOGIN_INFO: (state, data) => {
      if (data) {
        state.currentUserLoggedIn = data;
      }
    },
    END_SESSION: (state) => {
      HTTP.defaults.headers.Authorization = "";
      state.token = "";
      state.currentUserLoggedIn = null;
      localStorage.removeItem("MIBITES-USER-TKN");
    },
  },
};
