import axios from "axios";
import router from "../router"; // Assuming you have a router instance set up
import store from "@/store";

var baseDomain;

if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  // Code to be executed when the environment is localhost
  baseDomain = "http://localhost:3000";
  console.log("App Started: Local Enviroment");
} else {
  baseDomain = "https://app.mibites.com";
}

var baseUrl = `${baseDomain}/api`;

export var HTTP = axios.create({
  baseURL: baseUrl,
});

// Add an Axios interceptor to handle 401 unauthorized responses
HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      //Unauthorized, redirect to the login page
      store.dispatch("AUTH_LOGOUT");
      if (router.currentRoute.name != "Login") {
        //router.push("/login");
      }
    }
    return Promise.reject(error);
  }
);
