import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#E84C3D", // Set your desired primary color
      },
      dark: {
        primary: "#E84C3D", // Set your desired primary color for dark theme
      },
    },
    // dark: true,
  },
});
