import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import VueMoment from "vue-moment";
import Notifications from "vue-notification";
import MediumEditor from "vuejs-medium-editor";
import Meta from "vue-meta";

Vue.use(Meta);
Vue.component("medium-editor", MediumEditor);
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);
import "medium-editor/dist/css/medium-editor.css";
import "vuejs-medium-editor/src/themes/default.css";
// for the code highlighting
import "highlight.js/styles/github.css";

Vue.config.productionTip = false;
Vue.use(VueMoment);
Vue.filter("currency", function (value, currencyCode) {
  // Check if the value is a number
  if (typeof value !== "number") {
    return value;
  }

  // Format the number as currency based on the provided currency code
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
  });

  return formatter.format(value);
});
store.dispatch("initializeSession");
Vue.use(Notifications);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
