<template>
  <v-app class="app safe-area-top">
    <router-view />
    <notifications group="foo" position="bottom center" />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
:root {
  --default-font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
}
* {
  font-family: "DM Sans", sans-serif;
}

.safe-area-top {
  padding-top: constant(safe-area-inset-top); /* iOS 11.2+ */
  padding-top: env(safe-area-inset-top);
}

.v-btn,
.v-tab,
.v-list {
  text-decoration: none !important;
  text-transform: capitalize !important;
  letter-spacing: normal !important;
}

.v-tab {
  background: none;
  justify-content: left !important;
  padding: 0px 5px !important;
  margin: 0px 15px 0px 0px !important;
  min-width: auto !important;
}

.read-font {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
}
.link {
  /* color: #4caf4f; */
  cursor: pointer;
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
  /* color: rgb(60, 147, 69); */
}
.v-slide-group {
  display: flex;
  flex-direction: row-reverse;
}
.sticky {
  position: sticky;
  z-index: 1;
  top: 0;
}
.sticky-0 {
  position: sticky;
  top: 0px;
  z-index: 1;
}
.sticky-10 {
  position: sticky;
  top: 10px;
  z-index: 1;
}
.sticky-20 {
  position: sticky;
  top: 74px;
  z-index: 1;
}
.sticky-30 {
  position: sticky;
  top: 84px;
  z-index: 1;
}
.sticky-40 {
  position: sticky;
  top: 94px;
  z-index: 1;
}
.line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to two lines */
  -webkit-box-orient: vertical;
}
.line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to three lines */
  -webkit-box-orient: vertical;
}
.scale-on-hover:hover {
  transform: scale(1.01);
  cursor: pointer;
}
.scale-on-hover {
  transition: transform 0.1s ease-in-out !important;
}

.active-tab {
  border-bottom: 1px solid black;
}
</style>
