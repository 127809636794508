import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../Pages/LoginPage.vue"),
  },

  {
    path: "/",
    component: () => import("../layouts/MibitesLanding.vue"),
    children: [
      {
        path: "",
        name: "home page",
        component: () => import("../Page/MainPages/HomePage"),
      },
      {
        path: "/contact",
        name: "contact us view",
        component: () => import("../Page/MainPages/ContactUs"),
      },
      {
        path: "/discover",
        component: () => import("../Page/DiscoverPages/DiscoverView"),
        children: [
          {
            path: "/",
            name: "Discover Page",
            component: () =>
              import("../Pages/Reader/DiscoverTabs/DiscoverPosts"),
          },
          {
            path: "stories",
            name: "discover stories",
            component: () =>
              import("../Pages/Reader/DiscoverTabs/DiscoverStories"),
          },
          {
            path: "news",
            name: "discover News",
            component: () => import("../Pages/Reader/DiscoverTabs/DiscoverRss"),
          },
        ],
      },
    ],
  },
  {
    path: "/profile",
    component: () => import("../layouts/MyPageLayout.vue"),
    children: [
      {
        path: "",
        name: "home page",
        component: () => import("../Page/ProfilePages/ProfileHome"),
      },
      {
        path: "edit",
        name: "edit profile",
        component: () => import("../Page/ProfilePages/EditProfile"),
        meta: {
          hideTab: true,
        },
      },
      {
        path: "edit/username",
        name: "edit profile",
        component: () => import("../Page/ProfilePages/ChangeUsername"),
        meta: { hideTab: true },
      },
      {
        path: "bites",
        component: () => import("../Page/ProfilePages/ProfileBites"),
        children: [
          {
            path: "",
            name: "manage bite",
            component: () => import("../Page/ProfilePages/Bites/BitesList"),
          },
          {
            path: ":id",
            name: "manage bite",
            component: () => import("../Page/ProfilePages/Bites/ManageBite"),
          },
        ],
      },
      {
        path: "stories",
        component: () => import("../Page/ProfilePages/ProfileBites"),
        children: [
          {
            path: "",
            name: "profile stories",
            component: () => import("../Page/ProfilePages/Stories/StoriesList"),
          },
          {
            path: ":id",
            name: "manage story",
            component: () => import("../Page/ProfilePages/Stories/ManageStory"),
          },
        ],
      },
    ],
  },
  {
    path: "/profile/create/bite",
    name: "create bite view",
    component: () => import("../Page/ProfilePages/Create/CreateBite.vue"),
    meta: { hideTab: true },
  },
  {
    path: "/profile/create/bite/ai",
    name: "create bite ai",
    component: () => import("../Page/ProfilePages/Create/CreateBiteWithAi.vue"),
    meta: { hideTab: true },
  },
  {
    path: "/profile/update/bite/:id",
    name: "update bite view",
    component: () => import("../Page/ProfilePages/Create/UpdateBite.vue"),
    meta: { hideTab: true },
  },
  {
    path: "/bites/:id",
    name: "bites id view",
    component: () => import("../Pages/Reader/BitesView"),
  },
  {
    path: "/news/:id",
    name: "news id view",
    component: () => import("../Pages/Reader/NewsView"),
  },
  {
    path: "/stories/:id",
    name: "stories id view",
    component: () => import("../Pages/Reader/StoryView"),
  },
  {
    path: "/stories/:id/chapter/:chapterId",
    name: "chapter id view",
    component: () => import("../Pages/Reader/ChapterView"),
  },
  {
    path: "/topic",
    component: () =>
      import("../Pages/Reader/DiscoverTabs/DiscoverByTopicsMain"),
    children: [
      {
        path: "/",
        name: "topics home",
        component: () => import("../Pages/Reader/DiscoverTabs/TopicsHome"),
      },
      {
        path: ":id",
        name: "topics page",
        component: () => import("../Pages/Reader/DiscoverTabs/TopicsWiseData"),
      },
    ],
  },

  {
    path: "/discover/authors",
    name: "Discover Page",
    component: () => import("../views/SearchResults"),
  },

  {
    path: "/v",
    component: HomeView,
    children: [
      {
        path: "/",
        component: () => import("../Pages/me/MainHomePage.vue"),
        children: [
          {
            path: "/",
            redirect: "bites",
            name: "landing page author",
            component: () => import("../Pages/me/tabs/BitesTab.vue"),
          },
          {
            path: "bites",
            name: "landing bites author",
            component: () => import("../Pages/me/tabs/BitesTab.vue"),
          },
          {
            path: "stories",
            name: "stories page",
            component: () => import("../Pages/me/tabs/StoriesTab.vue"),
          },
          {
            path: "featured",
            name: "featured page",
            component: () => import("../Tabs/FeaturedTab.vue"),
          },
        ],
      },
      {
        path: "/edit-profile",
        name: "edit profile",
        component: () => import("../Pages/EditProfile"),
      },
      {
        path: "/v/purchases",
        name: "purchases",
        component: () => import("../Pages/PurchasesView"),
      },
      {
        path: "/v/stats-and-earnings",
        name: "earnings",
        component: () => import("../Pages/StatsAndEarnings"),
      },
      {
        path: "create-bite",
        name: "Create Bite",
        component: () => import("../Pages/CreateBite.vue"),
      },
      {
        path: "/app/bites/:id/update",
        name: "Bites update",
        component: () => import("../Pages/UpdateBite.vue"),
      },
      {
        path: "/app/bites/:id",
        name: "Bites View",
        component: () => import("../Pages/ViewBite.vue"),
      },
      {
        path: "/app/stories/:id",
        name: "Stories View",
        component: () => import("../Pages/ViewStory.vue"),
      },

      {
        path: "/story/:id/chapter/new",
        name: "Update Story",
        component: () => import("../Pages/AddChapterStory.vue"),
      },
      {
        path: "/story/:id/chapter/:chapterId/edit",
        name: "Update Chapter",
        component: () => import("../Pages/EditChapterStory.vue"),
      },

      // {
      //   path: "/app",
      //   name: "app page",
      //   component: () => import("../views/LandingPage.vue"),
      // },
      // {
      //   path: "/app/ai-search",
      //   name: "AI Search",
      //   component: () => import("../views/AISearch.vue"),
      // },
      // {
      //   path: "/app/ai-search/results",
      //   name: "AI Search",
      //   component: () => import("../views/SearchResults.vue"),
      // },
      // {
      //   path: "/app/earnings-calculator",
      //   name: "Earnings Calculator",
      //   component: () => import("../views/EarningsCalculator.vue"),
      // },
      // {
      //   path: "/app/about",
      //   name: "about",
      //   component: () => import("../views/AboutView.vue"),
      // },
    ],
  },

  {
    path: "/:id",
    component: () => import("../layouts/MySiteLanding.vue"),
    children: [
      {
        path: "",
        component: () => import("../Page/MySitePages/MySite.vue"),
        children: [
          {
            path: "/",
            name: "my site page",
            component: () => import("../Page/MySitePages/Tabs/MySiteBites.vue"),
          },
          {
            path: "bites",
            name: "author bites",
            component: () => import("../Page/MySitePages/Tabs/MySiteBites.vue"),
          },

          {
            path: "stories",
            name: "suthor stories",
            component: () => import("../Pages/Reader/Tabs/StoriesTab.vue"),
          },
        ],
      },
      {
        path: "bites/:biteId",
        name: "author bites view",
        component: () => import("../Page/MySitePages/BiteView.vue"),
      },
    ],
  },

  {
    path: "/:id",
    component: () => import("../Pages/Reader/AuthorView2.vue"),
    children: [
      {
        path: "/",
        name: "authors bites home",
        component: () => import("../Pages/Reader/Tabs/BitesTab.vue"),
      },
      {
        path: "bites",
        name: "author bites",
        component: () => import("../Pages/Reader/Tabs/BitesTab.vue"),
      },
      {
        path: "stories",
        name: "suthor stories",
        component: () => import("../Pages/Reader/Tabs/StoriesTab.vue"),
      },
      {
        path: "featured",
        name: "author featured",
        component: () => import("../Pages/Reader/Tabs/StoriesTab.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
